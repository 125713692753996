<template>
  <div class="userInfo">
    <div class="userInfo-tab row-center text-main">
      <div
        v-for="(item, index) in tabbar"
        :key="index"
        class="pointer"
        :class="tabbarIndex == index ? 'tabbarClass' : ''"
        @click="tabbarClick(index)"
      >
        {{ item }}
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="userInfo-detail" v-if="tabbarIndex == 0">
      <div class="user-avatsr row-center text-main">
        <div>头像：</div>
        <div class="position-relative avatar-img pointer">
          <img :src="query.avatar_url" alt="" v-if="query.avatar_url" />
          <img src="../../../assets/login/922028.png" alt="" v-if="!query.avatar_url" />
          <div class="position-absolute left-0 bottom-0 pointer posi-update-img">
            <img src="../../../assets/glxt/8.png" alt="" />
            <div class="position-absolute text-white">修改</div>
          </div>
          <div class="position-absolute el-posi-div">
            <el-upload
              class="upload-demo"
              action=""
              :show-file-list="false"
              accept=".jpg,.png,.jpeg"
              :http-request="uploadMainImg"
            >
            </el-upload>
          </div>
        </div>
        <div>改头像将对求职者可见</div>
      </div>
      <div class="user-id row-center text-main">
        <div>用户ID：</div>
        <div>{{ userDetail.id }}</div>
      </div>
      <div class="user-name row-center text-main">
        <div>姓名：</div>
        <div>{{ userDetail.name ? userDetail.name : userDetail.phone }}</div>
      </div>
      <div class="user-nickname row-center text-main">
        <div>昵称：</div>
        <div class="div-select">
          <el-select v-model="valueName" placeholder="请选择">
            <el-option
              v-for="item in optionsName"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>该内容将对求职者可见</div>
      </div>
      <div class="user-nickname user-zw row-center text-main">
        <div>职务：</div>
        <div class="div-input">
          <el-input v-model="query.job"></el-input>
        </div>
        <div>该内容将对求职者可见</div>
      </div>
      <div class="submit-button row">
        <el-button type="primary" @click="submitClickOne">保存</el-button>
      </div>
    </div>
    <!-- 账号与绑定 -->
    <div class="account-bind text-main" v-if="tabbarIndex == 1">
      <div class="phone-bind column-between">
        <div>绑定手机</div>
        <div class="space-between phone-num">
          <div>
            {{ userDetail.phone ? `手机号：${userDetail.phone}` : "你还未绑定手机号" }}
          </div>
          <div class="row-center pointer" v-if="userDetail.phone" @click="phoneClickOne">
            <img src="../../../assets/glxt/18.png" alt="" />
            <span class="text-blue">解绑</span>
          </div>
          <div class="row-center pointer" v-else @click="phoneClick">
            <img src="../../../assets/glxt/10.png" class="img-one" alt="" />
            <span class="text-blue">添加</span>
          </div>
        </div>
      </div>
      <div class="wx-bind column-between">
        <div class="wx-text">
          <div>绑定微信号</div>
          <div>绑定微信后，可以使用微信账号扫码登录</div>
        </div>
        <div class="wx-add space-between">
          <div>{{ userDetail.is_bindwx == 1 ? userDetail.user_name : "当前未绑定" }}</div>
          <div
            class="row-center pointer"
            v-if="userDetail.is_bindwx == 1"
            @click="wxClickOne"
          >
            <img src="../../../assets/glxt/18.png" class="img-one" alt="" />
            <span class="text-blue">解绑</span>
          </div>
          <div class="row-center pointer" v-else @click="wxClick">
            <img src="../../../assets/glxt/10.png" alt="" />
            <span class="text-blue">添加</span>
          </div>
        </div>
      </div>
      <!-- 绑定邮箱 -->
      <div class="wx-bind column-between" v-if="emailShow">
        <div class="wx-text">
          <div>绑定邮箱</div>
          <div>绑定邮箱后，人才的附件简历将会送达至该邮箱</div>
        </div>
        <div class="wx-add space-between">
          <div>{{ userDetail.email ? userDetail.email : "当前未绑定" }}</div>
          <div class="row-center pointer" v-if="userDetail.email" @click="emailClick(2)">
            <img src="../../../assets/glxt/18.png" class="img-one" alt="" />
            <span class="text-blue">解绑</span>
          </div>
          <div class="row-center pointer" v-else @click="emailClick(1)">
            <img src="../../../assets/glxt/10.png" alt="" />
            <span class="text-blue">添加</span>
          </div>
        </div>
      </div>
      <div class="email-bind" v-else>
        <div>绑定邮箱</div>
        <div class="email-input">
          <el-input
            :placeholder="placeholder"
            :disabled="emailDisabled"
            v-model="emailQuery.email"
          ></el-input>
        </div>
        <div class="div-input space-between">
          <input type="text" placeholder="验证码" v-model="emailQuery.code" />
          <div class="text-blue pointer" @click="codeClick(2)">{{ second }}</div>
        </div>
        <div class="email-btn row-end text-main">
          <div class="flex-center pointer" @click="emailShow = true">取消</div>
          <div class="bg-blue text-white flex-center pointer" @click="emailSubmitClick">
            确认
          </div>
        </div>
      </div>
      <div style="padding-left: 32px">密码</div>
      <!-- 设置密码 -->
      <div
        class="row-center font-normal text-main current-password"
        style="padding-left: 32px"
      >
        <span>当前登录账号：</span>
        <div class="password-phone">
          {{ userDetail.phone ? userDetail.phone : "暂未绑定手机号码" }}
        </div>
      </div>
      <!-- 已设置登录密码 -->
      <div
        class="row-center font-normal text-main current-password"
        v-if="userDetail.password && userDetail.phone"
        style="padding-left: 32px"
      >
        <span>当前密码：</span>
        <div class="password-phone password-phone-one">*********</div>
      </div>
      <div class="password-input row">
        <div class="password-text">设置登录密码</div>
        <div class="input-div">
          <div>
            <el-input
              placeholder="请输入密码"
              type="password"
              v-model="passQuery.password"
            ></el-input>
          </div>
          <div>
            <el-input
              placeholder="请确认密码"
              type="password"
              v-model="passQuery.repwd"
            ></el-input>
          </div>
          <div class="space-between el-code-div">
            <el-input type="text" placeholder="验证码" v-model="passQuery.code" />
            <div class="text-blue pointer el-code-div" @click="codeClick(3)">
              {{ second }}
            </div>
          </div>
          <div class="row-end">
            <div
              class="text-white flex-center bg-blue pointer set-pass"
              @click="passwordSubmit"
            >
              设置密码
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我的公司 -->
    <div class="my-company space-between" v-if="tabbarIndex == 2 && entDetail">
      <div class="company-left">
        <div class="text-main">我的公司</div>
        <div class="row-center" v-if="entDetail">
          <img :src="entDetail.logo_url" alt="" />
          <div class="company-address direction-between">
            <div class="text-main">{{ entDetail.ent_name }}</div>
            <div>
              <span>企业编码：{{ entDetail.id }}</span>
              <span>{{ entDetail.join_time }}加入</span>
            </div>
          </div>
        </div>
      </div>
      <div class="company-right direction-between">
        <div class="text-blue flex-center" @click="replaceClick">更换企业</div>
        <div class="text-blue flex-center" @click="leaveClcik">离开企业</div>
        <div class="text-blue flex-center pointer" @click="navTo">公司主页</div>
      </div>
    </div>
    <!-- 弹窗 -->
    <mask-popup
      ref="replace"
      :colseShow="false"
      @submitClick="submitClick"
      title="身份安全验证"
      :btnCancel="false"
      :width="width"
      :interview="false"
    >
      <div class="replace-input">
        <div class="div-input space-between">
          <input type="text" placeholder="请输入验证码" v-model="leveQuery.code" />
          <div class="text-blue pointer" @click="codeClick(0)">{{ second }}</div>
        </div>
        <div>{{ userDetail.phone }}</div>
        <div>为了账号安全，我们仍需要通过手机号验证您的身份</div>
      </div>
      <div class="hint-content text-main" v-if="hintShow">
        您已成功与企业解除员工关系，账号中的数据已锁定，可由公司其他账号管理
      </div>
    </mask-popup>

    <mask-popup-two
      title="提示"
      sureContent="我知道了"
      :btnCancel="false"
      width="480px"
      :number="numberLever"
      ref="leverMask"
    >
      <div class="hint-content text-main">
        您已成功与企业解除员工关系，账号中的数据已锁定，可由公司其他账号管理
      </div>
    </mask-popup-two>

    <mask-popup-one :title="title" ref="maskRepalce" @submitClick="submitClickTWO">
      <div class="replace-center">
        <div>{{ titleOne }}，您将无法再使用企业账号;</div>
        <div>您发布的职位将被下线；</div>
        <div>您的人才数据与资产将保留，可由超管、企业其他的成员进行管理；</div>
        <div>您与人才的聊天将会失效。</div>
      </div>
    </mask-popup-one>
    <!-- 修改和添加手机号码 -->
    <mask-model
      sureContent="保存"
      ref="phoneModel"
      :title="titlePhone"
      width="480px"
      :btnCancel="false"
      :btnSubShow="false"
      @hide="Addhide"
    >
      <div class="edit-phone">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item :label="labelPhone" prop="phone">
            <el-input
              v-model="ruleForm.phone"
              :maxlength="11"
              :clearable="true"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <div class="el_code-div space-between">
              <el-input v-model="ruleForm.code" placeholder="请输入验证码"></el-input>
              <el-button
                type="primary"
                :disabled="disabled"
                @click="codeClick(1)"
                style="margin-left: 10px"
                >{{ second }}</el-button
              >
            </div>
          </el-form-item>
          <el-form-item
            style="display: flex; justify-content: flex-end; margin-bottom: 0"
          >
            <el-button @click="AddhideOne">取消</el-button>
            <el-button type="primary" @click="submitClickPhone('ruleForm')"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </mask-model>
    <div v-show="wxBindShow">
      <div
        class="position-fixed top-0 left-0 right-0 bottom-0"
        style="background-color: rgba(0, 0, 0, 0.5); z-index: 99"
        @click.stop="hide"
      ></div>
      <div class="mask-company animated faster fadeIn">
        <div class="space-between">
          <div class="text-main"></div>
          <i class="el-icon-close pointer" @click="hide"></i>
        </div>
        <div class="wx-edit direction">
          <div id="login_container"></div>
        </div>
        <div class="row-center mask-btn">
          <div class="text-blue flex-center pointer">取消</div>
        </div>
      </div>
    </div>
    <!-- 解绑微信号验证手机号码 -->
    <mask-model
      title="请验证手机号码"
      width="530px"
      ref="wxPhone"
      :interview="false"
      @submitClick="wxPhoneSubmit"
    >
      <div class="wx-phone">
        <div class="row-center wx-phone-text text-main font-normal">
          <div>当前登录账号</div>
          <div>{{ userDetail.phone }}</div>
        </div>
        <div class="space-between wx-phone-input font-normal">
          <div>短信验证码</div>
          <div class="div-input space-between one">
            <input type="text" placeholder="验证码" v-model="code" />
            <div class="text-blue pointer font-normal" @click="codeClickOne">
              {{ secondOne }}
            </div>
          </div>
        </div>
      </div>
    </mask-model>
  </div>
</template>

<script>
// 弹窗组件
import maskPopup from "../../../components/mask";
import maskPopupOne from "../../../components/mask";
import maskPopupTwo from "../../../components/mask";
import maskModel from "../../../components/mask";
import phoneEdit from "./phoneEdit.vue";
import { mapState } from "vuex";
import apiTool from "../../../api/tool";
import api from "../../../api/user";
export default {
  name: "userInfo",
  components: {
    maskPopup,
    phoneEdit,
    maskModel,
    maskPopupOne,
    maskPopupTwo,
  },
  computed: {
    // 2. 解构状态作为计算属性
    ...mapState(["userDetail", "entDetail"]),
    disabled() {
      return (
        this.ruleForm.phone === "" || !this.$util.checkStr(this.ruleForm.phone, "mobile")
      );
    },
  },
  data() {
    // 自定义验证手机号码以及短信验证码
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else if (!this.$util.checkStr(value, "mobile")) {
        callback(new Error("请输入正确的手机号码!"));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入短信验证码"));
      } else if (!this.$util.checkStr(value, "payPwd")) {
        callback(new Error("请输入正确的短信验证码!"));
      } else if (this.tisp === "获取验证码") {
        callback(new Error("请先获取短信验证码!"));
      } else {
        callback();
      }
    };
    return {
      heardShow: false,
      tabbar: ["个人信息", "账号与绑定", "我的公司"],
      tabbarIndex: 0,
      // 弹窗标题
      title: "",
      //弹窗宽度
      width: "",
      second: "发送验证码",
      // 判断是否处于验证码倒计时中
      showText: true,
      // 身份验证弹窗
      verifyShow: false,
      sureContent: "确定",
      // 提示
      hintShow: false,
      // 绑定邮箱显示
      emailShow: true,
      ruleForm: {
        phone: "",
        code: "",
      },
      // 验证规则
      rules: {
        phone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入6位验证码", trigger: "blur" },
          {
            validator: validateCode,
            trigger: "blur",
          },
        ],
      },
      //个人信息
      query: {
        avatar_url: "",
        user_name: "",
        job: "",
      },
      titlePhone: "添加手机号",
      phoneType: 0,
      labelPhone: "",
      placeholder: "请输入您要绑定的邮箱",
      emailDisabled: false,
      // 邮箱数据
      emailQuery: {
        email: "",
        code: "",
      },
      emailType: 2,
      passQuery: {
        password: "",
        repwd: "",
        code: "",
      },
      leveQuery: {
        type: 1,
        code: "",
      },
      titleOne: "更换后",
      leveShow: false,
      numberLever: 1,
      wxBindShow: false,
      setInterTime: null,
      secondOne: "发送验证码",
      code: "",
      optionsName:[],
      valueName:0
    };
  },

  created() {
    if (this.userDetail) {
      this.query.user_name = this.userDetail.user_name;
      this.query.avatar_url = this.userDetail.avatar_url;
      this.query.job = this.userDetail.job ? this.userDetail.job : "招聘者";
    }
    if(this.userDetail.name){
      if(this.userDetail.gender==1){
        this.optionsName=[
          {
            value:0,
            label:this.userDetail.name
          },
          {
            value:1,
            label:this.userDetail.name.slice(0,1)+'先生'
          }
        ]
      }else if(this.userDetail.gender==2){
         this.optionsName=[
          {
            value:0,
            label:this.userDetail.name
          },
          {
            value:1,
            label:this.userDetail.name.slice(0,1)+'女士'
          }
        ]
      }else{
         this.optionsName=[
          {
            value:0,
            label:this.userDetail.name
          },
          {
            value:1,
            label:this.userDetail.name.slice(0,1)+'女士'
          },
           {
            value:2,
            label:this.userDetail.name.slice(0,1)+'男士'
          }
        ]
      }
    }else{
        this.optionsName=[
          {
            value:0,
            label:this.userDetail.phone
          },
          {
            value:1,
            label:'先生/女士'
          }
        ]
    }
  let nameList=  this.optionsName.filter(item=>{
    // console.log(item,this.userDetail.user_name)
      return item.label==this.userDetail.user_name
    })
    if(nameList.length>0){
      this.valueName=nameList[0].value
    }
  },

  mounted() {},
  beforeDestroy() {
    clearInterval(this.setInterTime);
  },
  methods: {
    // 发送短信验证码
    codeClickOne() {
      const loading = this.$loading({
        lock: true,
        text: "正在获取验证码",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.showText == false) {
        this.$util.msg("请不要重复点击", "warning");
        loading.close();
        return false;
      } else {
        setTimeout(() => {
          loading.close();
          this.codeCountdownOne();
          this.sendSmsTwo();
        }, 2000);
      }
    },
    // 获取短信验证码
    sendSmsTwo() {
      api.getSms({ phone: this.userDetail.phone, type: 5 }).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg, "success");
        }
      });
    },
    // 倒计时
    // 验证码倒计时
    codeCountdownOne() {
      this.showText = false;
      let time = 60;
      if (time > 0) {
        var a = setInterval(() => {
          time = time - 1;
          this.secondOne = "重新发送" + time + "s";
          if (time == 0) {
            clearInterval(a);
            this.showText = true;
            this.secondOne = "发送验证码";
          }
        }, 1000);
      }
    },
    // 解绑微信
    unBindWechat() {
      api.unBindWechat({ code: this.code }).then((res) => {
        if (res.code == 200) {
          this.$refs.wxPhone.hide();
          this.$util.msg(res.msg);
          this.getUserInfo();
          this.showText = true;
        }
      });
    },
    // 解绑微信确定事件
    wxPhoneSubmit() {
      if (this.code.trim() == "") {
        this.$util.msg("请输入短信验证码", "warning");
        return;
      }
      this.unBindWechat();
    },
    // 解绑微信
    wxClickOne() {
      this.$refs.wxPhone.show();
    },
    hide() {
      this.wxBindShow = false;
      clearInterval(this.setInterTime);
    },
    // 微信生成二维码
    getWXQRCode() {
      this.$nextTick(() => {
        var obj = new WxLogin({
          self_redirect: false, // 这个为true的时候是直接在扫码的区域展现页面，我不需要，所以直接写false
          id: "login_container", // 我在上面定义的id名称
          appid: "wxb98bb66c3c7ae918", // 申请的网站应用appid
          scope: "snsapi_login", // 网页授权，目前网页只有这一种方式，静默授权
          redirect_uri: "https%3A%2F%2Fganfangzhi.com%2F%23%2Flayout%2FuserInfo", // 申请网站应用时填写的回调域名，这里对应即可
          state: Math.random(), // 简单的随机数+Session用来校验，
          style: "", // black、white两种可以选择，二维码样式（默认black）
          href: "", // 自定义样式链接，只能使用https链接或者base64编码的地址(修改二维码的样式，要经过base64位转码，地址为（https://the-x.cn/base64/）)
        });
      });
    },
    navTo() {
      api.toggleUser({ user_type: 1 }).then((res) => {});
      this.$nextTick(() => {
        const routeUrl = this.$router.resolve({
          path: "/Company-details",
          query: { id: this.entDetail.id },
        });
        window.open(routeUrl.href, "_blank");
      });
    },
    // 获取用户信息
    getUserInfo() {
      api.userDetail().then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_USER", res.data);
        }
      });
    },
    // 个人信息
    submitClickOne() {
     this.query.user_name=this.optionsName[this.valueName].label
      api.setUserBasic(this.query).then((res) => {
        if (res.code == 200) {
          this.$util.msg("更新成功");
          this.getUserInfo();
        }
      });
    },
    // 上传头像
    uploadMainImg(file) {
      if(file.file.type!="image/png" &&  file.file.type!="image/jpg" && file.file.type!="image/jpeg"){
        this.$util.msg('请上传png,jpg,jpeg格式的图片','error')
        return
      }
      const isLt2M = file.file.size / 1024 / 1024 < 6;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
        return;
      }
      // 创建一个formData对象实例的方式
      const data = new FormData();
      data.append("iFile", file.file);
      apiTool
        .uploadImg(data)
        .then((res) => {
          this.query.avatar_url = res.data.file_path;
        })
        .catch((err) => {
          this.$message({
            showClose: true,
            message: "上传失败",
            type: "error",
          });
        });
    },
    // 切换tab
    tabbarClick(index) {
      this.tabbarIndex = index;
    },
    // 更换企业
    replaceClick() {
      this.title = "确认更换企业？";
      this.titleOne = "更换后";
      this.$refs.maskRepalce.show();
      this.leveShow = false;
      this.numberLever = 1;
    },
    // 离开企业
    leaveClcik() {
      this.title = "确认离开企业？";
      this.titleOne = "离开后";
      this.$refs.maskRepalce.show();
      this.leveShow = true;
      this.numberLever = 2;
    },
    submitClickTWO() {
      this.width = "510px";
      this.$refs.replace.show();
    },
    // 点击了确定
    submitClick() {
      if (this.leveQuery.code.trim() == "") {
        this.$util.msg("请输入验证码", "error");
        return;
      }
      if (this.leveShow) {
        this.leveQuery.type = 1;
      } else {
        this.leveQuery.type = 2;
      }
      api.leaveEnterprise(this.leveQuery).then((res) => {
        if (res.code == 200) {
          this.$refs.replace.hide();
          this.$refs.leverMask.show();
        }
      });
    },
    // 获取短信验证码
    sendSms(type) {
      let typeOne;
      type == 1 ? (typeOne = 2) : (typeOne = 3);
      let phone;
      if (type == 1) {
        phone = this.ruleForm.phone;
      } else {
        phone = this.userDetail.phone;
      }
      api.getSms({ phone, type: typeOne }).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg, "success");
        }
      });
    },
    // 获取短信验证码
    sendSmsOne() {
      api.getSms({ phone: this.userDetail.phone, type: 4 }).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg, "success");
        }
      });
    },
    passYzgz() {
      if (this.userDetail.phone == "") {
        this.$util.msg("请先绑定手机号", "error");
        return false;
      } else if (this.passQuery.password.trim().length < 5) {
        this.$util.msg("请输入大于6位的密码", "error");
        return false;
      } else if (this.passQuery.repwd.trim().length < 5) {
        this.$util.msg("请输入大于6位确认密码", "error");
        return false;
      } else if (this.passQuery.password.trim() !== this.passQuery.repwd.trim()) {
        this.$util.msg("两次密码不一致", "error");
        return false;
      } else {
        return true;
      }
    },
    // 设置登录密码
    passwordSubmit() {
      if (!this.passYzgz()) return;
      if (!this.$util.checkStr(this.passQuery.code, "mobileCode")) {
        this.$util.msg("请输入六位数验证码", "error");
        return;
      }
      api.setPassword(this.passQuery).then((res) => {
        if (res.code == 200) {
          this.$util.msg(res.msg);
          this.$util.objClear(this.passQuery);
          this.getUserInfo();
        }
      });
    },
    // 发送短信验证码
    codeClick(type) {
      if (type == 2 && this.emailType == 1) {
        if (!this.$util.checkStr(this.emailQuery.email, "email")) {
          this.$util.msg("请输入正确的邮箱号", "error");
          return;
        }
      }
      if (type == 3) {
        if (!this.passYzgz()) {
          return;
        }
      }
      const loading = this.$loading({
        lock: true,
        text: "正在获取验证码",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.showText == false) {
        this.$util.msg("请不要重复点击", "warning");
        return false;
      } else {
        setTimeout(() => {
          loading.close();
          if (type == 1 || type == 3) {
            this.sendSms(type);
          } else if (type == 2) {
            this.sendEmail();
          } else {
            this.sendSmsOne();
          }
          this.codeCountdown();
        }, 2000);
      }
    },
    // 发送邮箱验证码
    sendEmail() {
      api
        .emailCode({ email: this.emailQuery.email, type: this.emailType })
        .then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
          }
        });
    },
    // 倒计时
    // 验证码倒计时
    codeCountdown() {
      this.showText = false;
      let time = 60;
      if (time > 0) {
        var a = setInterval(() => {
          time = time - 1;
          this.second = "重新发送" + time + "s";
          if (time == 0) {
            clearInterval(a);
            this.showText = true;
            this.second = "发送验证码";
          }
        }, 1000);
      }
    },
    // 添加手机号码
    phoneClick() {
      this.titlePhone = "添加手机号";
      this.labelPhone = "新手机号";
      this.phoneType = 0;
      this.$refs.phoneModel.show();
    },
    // 解绑手机号码
    phoneClickOne() {
      this.titlePhone = "解绑手机号";
      this.labelPhone = "旧手机号";
      this.phoneType = 1;
      this.$refs.phoneModel.show();
    },
    //解绑和绑定手机号码
    submitClickPhone() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.phoneType == 1) {
            api.unBindPhone(this.ruleForm).then((res) => {
              if (res.code == 200) {
                this.$util.msg(res.msg);
                this.AddhideOne();
                this.getUserInfo();
              }
            });
          } else {
            api.bindPhone(this.ruleForm).then((res) => {
              if (res.code == 200) {
                this.$util.msg(res.msg);
                this.AddhideOne();
                this.getUserInfo();
              }
            });
          }
        }
      });
      this.ruleForm.phone = "";
      this.ruleForm.code = "";
    },
    Addhide() {
      this.$util.objClear(this.ruleForm);
    },
    AddhideOne() {
      this.$refs.phoneModel.hide();
      this.$util.objClear(this.ruleForm);
    },
    // 轮询获取绑定微信返回的code
    pollingClick() {
      this.setInterTime = setInterval(() => {
        // 获取微信登录传递过来的code
        let query = this.$route.query;
        if (query.code) {
          clearInterval(this.setInterTime);
          let code = query.code;
          this.bindWechat(code);
        }
      }, 1000);
    },
    // 绑定微信
    bindWechat(code) {
      api.bindWechat({ code }).then((res) => {
        if (res.code == 200) {
          this.wxAddShow = false;
          this.$util.msg(res.msg);
          this.getUserInfo();
        }
      });
    },
    // 绑定微信
    wxClick() {
      this.wxBindShow = true;
      this.getWXQRCode();
      this.pollingClick();
    },
    // 邮箱
    emailClick(type) {
      this.emailShow = false;
      this.emailType = type;
      if (type == 2) {
        this.emailQuery.email = this.userDetail.email;
        this.emailDisabled = true;
      } else {
        this.placeholder = "请输入您要绑定的邮箱";
        this.emailDisabled = false;
      }
    },
    emailSubmitClick() {
      if (!this.$util.checkStr(this.emailQuery.email, "email")) {
        this.$util.msg("请输入正确的邮箱号", "error");
        return;
      } else if (!this.$util.checkStr(this.emailQuery.code, "mobileCode")) {
        this.$util.msg("请输入六位数邮箱验证码", "error");
        return;
      }
      if (this.emailType == 1) {
        api.bindEmail(this.emailQuery).then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
            this.getUserInfo();
            this.$util.objClear(this.emailQuery);
            this.emailShow = true;
          }
        });
      } else {
        api.unBindEmail(this.emailQuery).then((res) => {
          if (res.code == 200) {
            this.$util.msg(res.msg);
            this.getUserInfo();
            this.$util.objClear(this.emailQuery);
            this.emailShow = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.userInfo {
  width: 100%;
  padding: 1.875rem 9.1875rem 0 9.125rem;
  .userInfo-tab {
    font-size: 1.25rem;
    div {
      margin-right: 2.4375rem;
    }
    .tabbarClass {
      font-size: 1.5rem;
      color: #126bf9;
    }
  }
  // 个人中心
  .userInfo-detail {
    margin-top: 1.1875rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    padding: 1.875rem;
    font-size: 1rem;
    .user-avatsr {
      margin-bottom: 2.5rem;
      .avatar-img {
        margin-left: 2.5625rem;
        margin-right: 0.6875rem;
        img {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
        }
        .posi-update-img {
          img {
            width: 100%;
            height: 100%;
            border-radius: 0;
          }
          div {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.875rem;
          }
        }
        .el-posi-div {
          width: 5rem;
          height: 5rem;
          border-radius: 50%;
          top: 0 !important;
          left: 0 !important;
          /deep/.upload-demo {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
          }
          /deep/.el-upload {
            width: 5rem;
            height: 5rem;
            border-radius: 50%;
          }
        }
      }
      > div:last-child {
        color: #8d92a1;
        font-size: 0.8125rem;
      }
    }
    .user-id {
      margin-bottom: 2.5rem;
      div:last-child {
        font-size: 0.875rem;
        margin-left: 1.625rem;
      }
    }
    .user-name {
      margin-bottom: 2.5rem;
      div:last-child {
        font-size: 0.875rem;
        margin-left: 2.625rem;
      }
    }
    .user-nickname {
      margin-bottom: 2.5rem;
      .div-select {
        margin-left: 2.625rem;
        width: 32.5625rem;
        height: 2.125rem;
        margin-right: 0.5625rem;
        /deep/.el-input__inner {
          width: 32.5625rem;
          height: 2.125rem;
          line-height: 2.125rem;
        }
        /deep/.el-input__icon {
          line-height: 2.125rem;
        }
      }
      .div-input {
        margin-left: 2.625rem;
        width: 32.5625rem;
        height: 2.125rem;
        margin-right: 0.5625rem;
        /deep/.el-input__inner {
          width: 32.5625rem;
          height: 2.125rem;
          line-height: 2.125rem;
        }
      }
      > div:last-child {
        font-size: 0.875rem;
        color: #8d92a1;
      }
    }
    .user-zw {
      margin-bottom: 1.3125rem;
    }
    .submit-button {
      justify-content: flex-end;
      /deep/.el-button {
        padding: 0;
        width: 5.625rem;
        height: 2.125rem;
        text-align: center;
        line-height: 2.125rem;
      }
    }
  }
  // 我的公司
  .my-company {
    margin-top: 1.1875rem;
    width: 100%;
    padding: 1.9375rem 1.9375rem 2.3125rem 1.9375rem;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    height: 13.4375rem;

    .company-left {
      > div:first-child {
        font-size: 1rem;
      }
      > div:last-child {
        margin-top: 2rem;
        height: 4.75rem;
        img {
          width: 4.75rem;
          height: 4.75rem;
          border-radius: 0.3125rem;
          margin-right: 0.875rem;
        }
        .company-address {
          height: 4.75rem;
          padding-top: 0.875rem;
          padding-bottom: 0.8125rem;
          font-size: 1.125rem;
          align-items: flex-start;
          div:last-child {
            color: #616a7c;
            font-size: 0.875rem;
            span:last-child {
              display: inline-block;
              margin-left: 1.25rem;
            }
          }
        }
      }
    }
    .company-right {
      padding-top: 0.375rem;
      height: 100%;
      div {
        width: 5.375rem;
        height: 2.125rem;
        border: 1px solid #126bf9;
        cursor: pointer;
      }
    }
  }
  .replace-center {
    margin-top: 1.8125rem;
    margin-bottom: 1.3125rem;
    color: #5f6983;
    font-size: 1rem;
    line-height: 26px;
  }
  .replace-input {
    margin-top: 1.5625rem;
    margin-bottom: 2rem;
    color: #8d92a1;
    font-size: 0.8125rem;
    .div-input {
      width: 27.25rem;
      height: 3rem;
      background: #ffffff;
      border: 1px solid #e3e7ed;
      padding-left: 1.125rem;
      padding-right: 0.6875rem;
      margin-bottom: 1.5rem;
      div {
        border-left: 1px solid #e3e7ed;
        padding-left: 0.625rem;
      }
    }
    > div:last-child {
      font-size: 0.75rem;
      margin-top: 1.4375rem;
    }
  }
  .hint-content {
    margin-top: 1.0625rem;
    margin-bottom: 1.8125rem;
    font-size: 0.875rem;
  }
  // 账号与绑定
  .account-bind {
    margin-top: 1.3125rem;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(221, 221, 221, 0.42);
    padding: 1.875rem 0 1.875rem 0;
    font-size: 1rem;
    .phone-bind {
      width: 100%;
      height: 3.9375rem;
      padding: 0 1.9375rem 0 2rem;
      .phone-num {
        font-size: 0.875rem;
        img {
          width: 0.8125rem;
          height: 0.75rem;
          margin-right: 0.4375rem;
        }
        .img-one {
          height: 0.8125rem;
        }
      }
    }
    .wx-bind {
      width: 100%;
      height: 5.375rem;
      margin: 3rem 0;
      padding: 0 1.9375rem 0 2rem;
      .wx-text {
        div:last-child {
          color: #8d92a1;
          font-size: 0.8125rem;
          margin-top: 0.625rem;
        }
      }
      .wx-add {
        font-size: 0.875rem;
        img {
          width: 0.8125rem;
          height: 0.8125rem;
          margin-right: 0.4375rem;
        }
        .img-one {
          height: 0.75rem;
        }
      }
    }
    .password-input {
      margin-top: 2.1875rem;
      padding: 0 1.9375rem 0 2rem;
      .password-text {
        height: 2.125rem;
        line-height: 2.125rem;
        margin-right: 1.0625rem;
      }
      .input-div {
        width: 27.5rem;
        > div {
          width: 100%;
          height: 2.125rem;
          margin-bottom: 1.1875rem;
        }
        .set-pass {
          width: 5.625rem;
          height: 2.125rem;
          margin-top: 1.25rem;
          font-size: 0.875rem;
        }
        .el-code-div {
          /deep/.el-input {
            width: 70%;
          }
          /deep/.el-input__inner {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
          .el-code-div {
            flex: 1;
            width: 100%;
            text-align: center;
            line-height: 40px;
            height: 40px;
            border: 0.0625rem solid #e3e7ed;
          }
        }
      }
    }
    .current-password {
      margin-top: 10px;
      .password-phone {
        color: #999999;
      }
    }
    .email-bind {
      width: 100%;
      height: 14.0625rem;
      background: #f8f9fb;
      margin-bottom: 3.8125rem;
      padding: 1.5625rem 1.9375rem 1.1875rem 1.9375rem;
      .email-input {
        margin-top: 0.75rem;
        margin-bottom: 1.5625rem;
        width: 19.75rem;
        height: 2.375rem;
      }
      .div-input {
        width: 19.75rem;
        height: 2.375rem;
        background: #ffffff;
        border: 1px solid #e3e7ed;
        padding-left: 1.125rem;
        padding-right: 0.6875rem;
        margin-bottom: 1.25rem;
        div {
          border-left: 1px solid #e3e7ed;
          padding-left: 0.625rem;
        }
      }
      .email-btn {
        div {
          width: 5.75rem;
          height: 2.25rem;
          margin-left: 1.1875rem;
        }
        div:first-child {
          background: #ffffff;
          border: 1px solid #e3e7ed;
        }
      }
    }
  }
  // 修改手机号码弹窗
  .phone-edit {
    margin-top: 2.25rem;
    margin-bottom: 2.5rem;
    /deep/.el-input-group__append {
      background: #ffffff;
      color: #4488f4;
      cursor: pointer;
      span {
        line-height: 38px;
        height: 38px;
      }
    }
  }
  // 修改微信
  .wx-edit {
    margin-top: 1.3125rem;
    margin-bottom: 2.6875rem;
    color: #999999;
    font-size: 0.875rem;
    img {
      width: 8.5rem;
      height: 8.5rem;
      margin-bottom: 0.6875rem;
    }
  }
  .edit-phone {
    width: 100%;
    margin: 36px 0 20px 0;
    padding-left: 19px;
    /deep/.el-input {
      width: 80%;
    }
    /deep/.el-form-item__error {
      left: 79px;
    }
    .el_code-div {
      margin-left: 78px;
      /deep/.el-input {
        width: 70%;
      }
    }
  }
  .mask-company {
    width: 30rem;
    background: #ffffff;
    border-radius: 3px;
    padding: 1.3125rem 1.3125rem 1.5rem 1.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    .mask-btn {
      justify-content: flex-end;
      div {
        width: 5.625rem;
        height: 2.125rem;
        margin-left: 0.875rem;
        font-size: 0.875rem;
      }
      div:first-child {
        border: 1px solid #126bf9;
      }
    }
  }
  .wx-phone {
    width: 100%;
    margin: 40px 0;
    padding-left: 10px;
    .wx-phone-text {
      div:last-child {
        margin-left: 30px;
      }
    }
    .wx-phone-input {
      margin-top: 40px;
      > div {
        padding-left: 14px;
      }
      .email-phone {
        padding-left: 40px;
      }
      .div-input {
        margin-left: 30px;
        width: 100%;
        height: 2.375rem;
        background: #ffffff;
        border: 1px solid #e3e7ed;
        padding-left: 1.125rem;
        padding-right: 0.6875rem;
        div {
          border-left: 1px solid #e3e7ed;
          padding-left: 0.625rem;
        }
      }
    }
  }
}
</style>
